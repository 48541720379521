@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=Noto+Serif:wght@700&family=Open+Sans:wght@300&family=Outfit:wght@100&family=Poppins:wght@300&family=Ruda&display=swap');


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #000030;
  color: white;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.searchBar {
  background-color: transparent;
  border: none;
  color: white;
  width: 300px;
  outline: none;
  font-size: 16px;
}

.review {
  display: flex;
  font-family: 'Open Sans';
  font-size: 20px;
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: none;
  color: white;
  transition: all 0.45s ease-in-out;
  resize: none;
}

.review:focus {
  outline: none;
  height: 200px;
  outline-color: gray;
}

.userValue {
  margin-bottom: 20px;
}

.Mui-focused {
  border: 1em;
  border-color: red;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

img.roundedcorner {
  border-radius: 50%;
  position: relative;
}